import { createSlice } from "@reduxjs/toolkit";
const initialState = { transactions: [] };

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    transactions: (state = initialState, { payload }) => {
      return {
        ...state,
        transactions: payload,
      };
    },
  },
  extraReducers: {},
});
