import { createSlice } from "@reduxjs/toolkit";
const initialState = { cardsList: [], transactions: [], status: [], preferences: {} };

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    cardsList: (state = initialState, {payload}) => {
      return {
        ...state,
        cardsList: payload,
      };
    },
    preferences: (state = initialState, {payload}) => {
      return {
        ...state,
        preferences: payload,
      };
    },
  },
  extraReducers: {},
});
