import { all, put, takeLatest, delay } from "redux-saga/effects";
import { successMessage } from "../../utilities/notification";
import { userslice } from "../slices/user";
import History  from "../services/history";

import {
  LOGIN,
  COMPANY_INFO,
  REGISTER,
  VERIFY_EMAIL,
  FORGOT_PASSWORD,
  LOGOUT,
  RESET_PASSWORD_LINK,
  UPDATE_PASSWORD,
} from "../actions/user.actions.types";

import httpClient from "../services/http.client";

import * as Effects from "redux-saga/effects";
import { businessSlice } from "../slices/business";
const call = Effects.call;

function* companyInfo({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/user/owner-registration-init",
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* registerHandler({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/user/owner-registration",
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    authorization: false,
  });
  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* verifyEmailHandler({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "verifyemail",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    successMessage(result.message);
    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* forgotPasswordHandler({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "forgetpassword",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    successMessage(result.message);
    if (callback) {
      callback({ success: false, data: null });
    }
  }
}

function* login({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/user/login",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(userslice.actions.setAuthenticationToken(result.data?.token));
    yield put(userslice.actions.loginAction(result.data));
    yield put(
      businessSlice.actions.fetchBusinessDataAction(result?.data?.company)
    );
    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* resetPasswordLink({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "put",
    url: "/user/reset-password-init",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      yield put(userslice.actions.setEmail(data?.email));

      callback({ success: true, data: null });
    }
  }
}

function* updatePassword({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/user/reset-password",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* logout({ payload }) {
  yield put(userslice.actions.logoutAction());
  yield delay(500);
  if (payload?.callback) {
    payload.callback({ success: true, data: null });
  } else {
    History.navigate("/login");
  }
}

function* User() {
  yield all([
    takeLatest(LOGIN, login),
    takeLatest(COMPANY_INFO, companyInfo),
    takeLatest(REGISTER, registerHandler),
    takeLatest(FORGOT_PASSWORD, forgotPasswordHandler),
    takeLatest(VERIFY_EMAIL, verifyEmailHandler),
    takeLatest(LOGOUT, logout),
    takeLatest(RESET_PASSWORD_LINK, resetPasswordLink),
    takeLatest(UPDATE_PASSWORD, updatePassword),
  ]);
}

export default User;
